import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Subscription } from 'rxjs'
import { BasicReturn, GetUserHashResponse, IntercomSettings } from './types'
import { ManageLoggedUser } from '../../../authentication/services/manage-logged-user.service'
import { HttpClientOptionsService } from '../../../authentication/services/http-client-options.service'
import { environment } from '../../../../../environments/environment'
// @ts-ignore
import loadIntercomMessenger from './intercomPlugin.js'

interface IntercomUserInfo {
    name: string
    email: string
    userRoleType: string
    hubPartnerId: string
    partner: string
    userHash?: string
}

@Injectable({
    providedIn: 'root',
})
export class IntercomService {
    private subscription!: Subscription
    private currentUser!: IntercomUserInfo

    constructor(
        private manageLoggedUser: ManageLoggedUser,
        private httpOptionsService: HttpClientOptionsService,
        private httpClient: HttpClient,
    ) {
        this.init()
    }

    async init() {
        this.subscription = this.manageLoggedUser.userLogged$.subscribe(newUserData => {
            if (newUserData && !this.currentUser) {
                this.currentUser = this.convertUser(newUserData)
                this.bootMessenger()
            }
            if (this.currentUser && newUserData === null) {
                this.shutDownMessenger()
                this.clearLastUpdate()
            }
        })
    }

    convertUser(newUserData: any): IntercomUserInfo {
        return {
            name: newUserData.account_user_name,
            email: newUserData.account_user_email,
            userRoleType: newUserData.account_user_role_type_alias,
            hubPartnerId: newUserData.hub_partner_id,
            partner: newUserData.account_user_partner,
        }
    }

    isAllowedToBoot(result: GetUserHashResponse): boolean {
        if (!result.success) return false
        this.currentUser.userHash = result.userHash
        return true
    }

    async bootMessenger() {
        try {
            if (this.shouldUpdateUserData()) {
                // @ts-ignore
                const { success } = await this.updateUserData()
                if (success) {
                    this.saveLastTimeOfUpdatedUserData()
                } else {
                    this.clearLastUpdate()
                    console.log('Falha ao atualizar usuário no Guru.')
                }
            }

            const result = await this.getUserHash()
            //@ts-ignore
            if (!this.isAllowedToBoot(result)) return

            const settings: IntercomSettings = {
                app_id: environment.intercom_app_id,
                email: this.currentUser.email,
                user_hash: this.currentUser.userHash,
            }
            loadIntercomMessenger(environment.intercom_app_id)
            window.Intercom('boot', settings)
        } catch (error) {
            console.log('Guru não iniciado.')
        }
    }

    getUserHash() {
        const url = `${environment.URL_BASE}/partnerhub/intercom/get-messenger-hash`
        const options = this.httpOptionsService.getPartnerhubAuthorization()
        return this.httpClient.get<GetUserHashResponse>(url, options).toPromise()
    }

    updateUserData() {
        const url = `${environment.URL_BASE}/partnerhub/intercom/update-user`
        const options = this.httpOptionsService.getPartnerhubAuthorization()
        return this.httpClient.get<BasicReturn>(url, options).toPromise()
    }

    // @ts-ignore
    shouldUpdateUserData() {
        const lastUpdate = localStorage.getItem('intercom-last-update')
        if (!lastUpdate) return true

        let lastUpdateInDate = new Date(lastUpdate)
        let today = new Date()
        let Difference_In_Time = today.getTime() - lastUpdateInDate.getTime()
        let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24))

        if (Difference_In_Days > 1) return true
    }

    saveLastTimeOfUpdatedUserData() {
        localStorage.setItem('intercom-last-update', new Date().toISOString())
    }

    clearLastUpdate() {
        localStorage.removeItem('intercom-last-update')
    }

    shutDownMessenger() {
        window.Intercom('shutdown')
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }
}
