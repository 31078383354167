import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { HttpClientOptionsService } from '../../legacy/authentication/services/http-client-options.service'

@Injectable({ providedIn: 'root' })
export class PartnersDataService {
    constructor(
        private httpClient: HttpClient,
        private httpClientOptions: HttpClientOptionsService,
    ) {}

    getCommercialPartnersData(body: any) {
        return this.httpClient.post<any>(
            `${environment.URL_BASE}/partnerhub/commercial-partner/get-commercial-partners-data`,
            body,
            this.httpClientOptions.getPartnerhubAuthorization(),
        )
    }
}
